<template>
  <div class="mt-5">
    <div v-if="isEditMode" class="d-flex justify-space-between">
      <text-btn icon="mdi-arrow-left" @click="moveBack">
        {{ backTitle }}
      </text-btn>
      <text-btn
        v-if="currentView !== 'questionnaire' && isQuestionnaireVisible"
        right
        icon="mdi-arrow-right"
        @click="moveForward"
      >
        {{ forwardTitle + "&nbsp;" }}
      </text-btn>
    </div>
    <Nav
      v-if="!isEditMode && !isParticipant"
      class="mt-8 d-flex justify-space-around"
      additionalItemClass="mx-6"
      :tabs="viewModeTabs"
      :currentTab="currentTab"
      @tab-selected="selectTab"
    />
    <component class="pa-0" :is="getComponent" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  provide,
  inject,
  watch,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";

import { useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const isEditMode = inject("isEditMode", ref(false));
    const isParticipant = computed(
      () => store.getters.getCurrentRole === "participant"
    );
    // Assessment aviliable for experts if report isn't finished
    //and for main expert anyway
    const isAssessmentAvailiable = computed(
      () =>
        isEditMode.value &&
        store.getters.getCurrentRole === "expert" &&
        (!store.getters.isReportCompleted ||
          store.getters.getCurrentReport.mainExpertId ===
            store.getters.getAuthData.id)
    );
    provide("isAssessmentAvailiable", isAssessmentAvailiable);
    // Availiable views: assessment, report, questionnaire, answers
    const currentView = ref(
      isAssessmentAvailiable.value && !store.getters.isReportCompleted
        ? "assessment"
        : "report"
    );
    const switchView = viewName => (currentView.value = viewName);
    provide("switchView", switchView);

    const isQuestionnaireEmpty = computed(
      () => !store.getters.getReportQuestionnaire.questions?.length
    );
    const isQuestionnaireVisible = computed(
      () =>
        !isQuestionnaireEmpty.value &&
        store.getters.isReportItemVisible("showQuestionnaire")
    );

    const backTitle = computed(() => {
      if (currentView.value === "assessment") return "К списку отчетов";

      if (currentView.value === "report") {
        if (isAssessmentAvailiable.value) return "К оценке индикаторов";
        else return "К списку отчетов";
      }

      if (currentView.value === "questionnaire") return "К отчету";
    });
    const moveBack = () => {
      if (currentView.value === "assessment") {
        store.commit("clearCurrentReport");
        return;
      }

      if (currentView.value === "report") {
        if (isAssessmentAvailiable.value) {
          switchView("assessment");
          return;
        } else {
          store.commit("clearCurrentReport");
          return;
        }
      }

      if (currentView.value === "questionnaire") {
        switchView("report");
        return;
      }
    };

    const forwardTitle = computed(() => {
      if (currentView.value === "assessment") return "К составлению отчета";

      if (currentView.value === "report") return "Анкета";
    });
    const moveForward = async () => {
      if (currentView.value === "assessment") {
        await store.dispatch("fetchCurrentReport", {
          sessionId: store.getters.getSessionId,
          role: store.getters.getCurrentRole,
          updateMode: true,
        });
        switchView("report");
        return;
      }

      if (currentView.value === "report") {
        switchView("questionnaire");
        return;
      }
    };

    const isReportItemVisible = store.getters.isReportItemVisible;
    const viewModeTabs = computed(() => {
      const tabs = [
        {
          name: "report",
          label: "Отчет",
        },
      ];
      if (isQuestionnaireVisible.value)
        tabs.push({
          name: "questionnaire",
          label: "Анкета",
        });
      if (
        isReportItemVisible("showVideoRecord") ||
        isReportItemVisible("showEmailTaskAnswers")
      )
        tabs.push({
          name: "answers",
          label: "Ответы",
        });
      return tabs;
    });
    const { currentTab, selectTab } = useTabs(
      isAssessmentAvailiable.value ? "assessment" : "report"
    );
    watch(
      () => currentTab.value,
      val => switchView(val)
    );

    const getComponent = computed(() => {
      switch (currentView.value) {
        case "assessment":
          return () => import("./Assessment");
        case "report":
          return () => import("./Main");
        case "questionnaire":
          return () => import("./Questionnaire");
        case "answers":
          return () => import("./Answers");
      }
    });

    onMounted(() => {
      if (store.getters.getCurrentRole !== "participant")
        localStorage.setItem("lastTab", "Report");
      localStorage.setItem(
        "sessionId",
        store.getters.getCurrentReport.sessionId
      );
    });

    onBeforeUnmount(async () => {
      store.commit("clearReports");
      await store.dispatch("fetchReports");
    });

    return {
      isEditMode,
      isParticipant,
      isQuestionnaireVisible,
      backTitle,
      moveBack,
      forwardTitle,
      moveForward,
      currentView,
      viewModeTabs,
      currentTab,
      selectTab,
      getComponent,
    };
  },
  components: {
    Nav,
  },
};
</script>
